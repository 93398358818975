import { CardButton, Popover, PopoverContent, PopoverTrigger, RadioInput } from '@fsg/gui-bits'
import classNames from 'classnames/bind'
import { ContactResponse } from '@app/types'
import { styles as duplicateCardStyles } from '../ui/styles'
import moduleStyles from '../ui/DuplicateCard.module.scss'
import { CircleAlertIcon } from 'lucide-react'

const { duplicateCard: styles } = duplicateCardStyles
const cx = classNames.bind(moduleStyles)

type Props = {
  contact: ContactResponse
  selectedMatchId: string
  onClickCard: (contact: ContactResponse) => void
}

function checkIsEmployee(contact: ContactResponse) {
  return contact.contactType.code === 'E'
}

export function DuplicateContactCard(props: Props) {
  const { contact, selectedMatchId, onClickCard } = props

  const disabled = checkIsEmployee(contact)

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) return
    onClickCard(contact)
  }

  return (
    <CardButton
      key={contact.id}
      className={cx('m--card', styles.cardButton, { 'm--card-selected': selectedMatchId === contact.id, '!bg-gray-100 opacity-50': disabled })}
      onClick={handleClick}
      disabled={disabled}
    >
      <CardContent {...props} />
    </CardButton>
  )
}

function CardContent(props: Props) {
  const { contact, selectedMatchId, onClickCard } = props

  const disabled = checkIsEmployee(contact)

  const handleRadioClick = (e: React.MouseEvent<HTMLInputElement>) => {
    // Stop the click event from propagating to the parent CardButton
    e.stopPropagation()
    if (disabled) return
    onClickCard(contact)
  }

  return (
    <>
      <div className={styles.cardHeader.wrapper}>
        <p className={cx(styles.cardHeader.title)}>{contact.fullName}</p>
        {disabled ? (
          <Popover>
            <PopoverTrigger>
              <CircleAlertIcon className="text-gray-900 w-[15px] h-[15px]" />
            </PopoverTrigger>
            <PopoverContent side="right" className="p-sm rounded-xs">
              <span className="text-sm">You cannot edit an employee contact.</span>
            </PopoverContent>
          </Popover>
        ) : (
          <RadioInput
            name="selectedContactMatch"
            checked={selectedMatchId === contact.id}
            id={contact.id}
            className={styles.cardHeader.radioInput}
            onClick={handleRadioClick}
            disabled={disabled}
          />
        )}
      </div>
      <div>
        <ul>
          {contact.currentRole ? (
            <li>
              <span className={styles.listItemBold}>
                <strong>{contact.currentRole.accountName}</strong>
              </span>
            </li>
          ) : null}
          {contact.primaryEmail ? (
            <li>
              <span className={styles.listItem}>{contact.primaryEmail?.address}</span>
            </li>
          ) : null}
          {contact.primaryPhone ? (
            <li>
              <span className={styles.listItem}>{contact.primaryPhone?.number}</span>
            </li>
          ) : null}
          {contact.currentRole ? (
            <li>
              <span className={styles.listItem}>{contact.currentRole.title}</span>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  )
}
