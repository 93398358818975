import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { NewContactFormData } from 'types/contacts'

import { useAppContextSelector } from '@app/components/AppContext'
import { SelectField } from '@app/components/Form/SelectField'
import { TextField } from '@app/components/Form/TextField'
import { AccountLookup } from '@app/components/Lookups/AccountLookup'
import { VALIDATIONS } from '@app/constants'
import { EmailValidator, formatCallingCodeOption, onBlurWorkaround } from '@app/utils'

import { FieldGroup } from '../ui/FieldGroup'

type Props = {
  form: UseFormReturn<NewContactFormData>
}

export function NewContactForm({ form }: Props) {
  const countries = useAppContextSelector((state) => state.countries)
  const callingCodeOptionsMap = new Map(countries.map((country) => [country.alpha3code, formatCallingCodeOption(country)]))
  const callingCodeOptions = Array.from(callingCodeOptionsMap.values())
  const emailValidator = new EmailValidator({ allowFsg: false })

  return (
    <div className="grid">
      <FieldGroup title="Contact Name" description="What name should FSG use to reference this contact in official communication?">
        <TextField required label="First Name" name="firstName" rules={{ required: VALIDATIONS.REQUIRED }} />
        <TextField required label="Last Name" name="lastName" rules={{ required: VALIDATIONS.REQUIRED }} />
      </FieldGroup>
      <FieldGroup title="Primary Email Address" description="Please provide the primary email address for this contact.">
        <TextField
          required
          label="Email Address"
          name="primaryEmail"
          rules={{
            required: VALIDATIONS.REQUIRED,
            validate: emailValidator.validate,
          }}
        />
      </FieldGroup>
      <FieldGroup title="Primary Phone Number" description="Please provide the primary phone number for this contact.">
        <SelectField label="Calling Code" name="callingCode" options={callingCodeOptions} isClearable={false} onBlur={onBlurWorkaround} />
        <TextField label="Phone Number" name="primaryPhone" rules={{ required: VALIDATIONS.REQUIRED, pattern: VALIDATIONS.PHONE_PATTERN }} />
        <TextField label="Extension" name="extension" />
      </FieldGroup>
      <FieldGroup title="Account Name" description="Select or create the company that this individual currently represents.">
        <AccountLookup name="role.accountId" label="Account Name" required rules={{ required: VALIDATIONS.REQUIRED }} onBlur={onBlurWorkaround} />
      </FieldGroup>
    </div>
  )
}