export const styles = {
  duplicateCard: {
    cardButton: 'group flex min-h-[100px] flex-col p-md text-xs',
    cardHeader: {
      wrapper: 'flex justify-between items-start mb-md',
      title: 'text-sm text-gray-900 font-semibold',
      radioInput: 'text-color-primary group-hover:text-color-primary',
    },
    listItem: 'text-gray-darkest text-xs',
    listItemBold: 'text-gray-darkest text-xs font-semibold',
    address: 'not-italic',
  },
}
